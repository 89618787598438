.timelog-content {
  padding: 10px 24px 0 24px;
}

.is-fullwidth {
  width: 100%;
}

.editorClassName {
  border: 1px solid rgb(217, 217, 217);
  border-radius: 4px;
  padding: 0 10px;
}

.editor-class {
  border: 1px solid black;
}

.dropdowns {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.header-title {
  margin-bottom: 20px;
  font-size: 11pt;
}

.dropdown {
  padding-right: 10px;
}

.totals {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  font-weight: 500;
  padding-left: 2px;
  padding-right: 50px;
  padding-bottom: 10px;
  font-size: 11pt;
}

.total-hours {
  padding-left: 20px;
}

.hidden .ant-table-row-expand-icon {
  display: none !important;
}

.hidden {
  background-color: #fff2e6;
  width: 30px;
}

.w100 {
  width: 100%;
}

.mt4 {
  margin-top: 4px!important;
}

.mlr8 {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.stats:before {
  content: "";
  display: inline-block;
  width: 15px;
  height: 10px;
  margin-right: 5px;
}

.totalHours:before {
  background: rgb(23, 145, 255);
}

.timeLogs:before {
  background: rgb(0, 21, 42);
}

.reportStatistics {
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  margin-bottom: 30px;
  text-align: center;
}

.generateReportManager {
  margin-top: 30px;
  display: flex!important;
  justify-content: flex-end;
}

.generateReportEmployee {
  margin-bottom: 30px;
  //display: flex!important;
  //justify-content: flex-end;
}
