.invoice-wrapper {
  .bg-dark {
    background: #f0f2f5;
    color: #666;
    padding: 10px 5px;
    text-align: center;
    margin-bottom: 1rem;
    font-weight: bold;
  }

  .customRow {
    margin-bottom: 1rem;
  }

  .totalLineAmount {
    display: inline;
    font-weight: bold;
    margin-right: 1rem;
  }

  .remoteItemButton {
    display: inline;
  }

  .lineAmount {
    text-align: center;
  }
}

.invoiceTableDetailsTitle {
  font-size: 15px !important;
}

.invoiceDocumentUploadButton {
  padding: 0 !important;
  border: none !important;
  color: rgba(0, 0, 0, 0.65) !important;
  background: transparent !important;
}

.invoiceDocumentUploadButton:hover,
.invoiceDocumentUploadButton:focus {
  padding: 0 !important;
  border: none !important;
  background: transparent !important;
  color: rgba(0, 0, 0, 0.65) !important;
}

.invoiceTotalAmount {
  margin: auto;
  position: relative;
  border-bottom: 1px solid #d9d9d9;
  padding: 10px 20px;
  text-align: center;
}

.customCol {
  float: right;
  text-align: center !important;
  margin: auto !important;
  width: 25%;
  padding: 0 2rem;
}

.totalAmountRow {
  float: right;
  clear: both;
  width: 100%;
}

.hidden {
  display: none !important;
}

.addInvoiceItem {
  margin-right: 1rem;
}

li.ant-dropdown-menu-item:empty {
  display: none !important;
}

.customAntTextarea,
.customAntInput {
  border: none !important;
  border-bottom: 1px solid #d9d9d9 !important;
}

.customAntTextarea:focus,
.customAntTextarea:hover {
  background: transparent !important;
  box-shadow: none !important;
}

.customAntInput:focus,
.customAntInput:hover {
  background: transparent !important;
  box-shadow: none !important;
}

.customColInvoiceDetails {
  display: flex;
  text-align: center !important;
  justify-content: center;
}

.customAntInput {
  max-width: 85%;
  text-align: center;
}

.select-client.ant-select.ant-select-enabled.ant-select-allow-clear,
span#month {
  width: 11rem !important;
}

.createInvoiceModal {
  max-width: 1000px;
}
