.forgotPasswordContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f0f1f5;
}

.forgotPassword {
  display: flex;
  justify-content: center;
  background-color: white;
  width: 400px;
  height: 475px;
  border-radius: 10px;
  // box-shadow: $boxShadow;
}

.submit-email {
  width: 100%;
}

// s
