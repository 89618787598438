.account-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.edit-profile {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.password-form {
  position: relative;
  padding: 20px 20px;
  background-color: #fff;
  border-radius: 2px;
  // box-shadow: $boxShadow;
  width: 48%;
  display: flex;
  flex-direction: column;
}

.profile-info {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px 20px;
  background-color: #fff;
  border-radius: 2px;
  // box-shadow: $boxShadow;
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.label {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei",
    "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 18pt;
  color: rgba(0, 0, 0, 0.76);
}

.profile-name {
  font-size: 20pt;
  font-weight: 400;
  font-family: "Trebuchet MS", "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Tahoma, sans-serif;
  color: #00152a !important;
}

.wrapper {
  width: 100%;
  margin: 20px 0;
  padding: 10px;
  background-color: #fff;
  border-radius: 2px;
  // box-shadow: $boxShadow;
}
.wrapper-height {
  width: 100%;
  height: 100%;
  margin: 20px 0;
  padding: 10px;
  background-color: #fff;
  border-radius: 2px;
  // box-shadow: $boxShadow;
}

.button-left {
  margin-left: 0px;
  margin-bottom: 10px;
}

.backward-button-wrapper {
  display: flex;
  flex-direction: column;
  width: 130px;
}

@keyframes pulsCollapse {
  0% {
    box-shadow: 0 0 0 0 rgba(245, 34, 45, 0.4);
  }
  70% {
    box-shadow: 0 5px 0 5px rgba(245, 34, 45, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(245, 34, 45, 0);
  }
}
@keyframes pulseMore {
  0% {
    box-shadow: 0 0 0 0 rgba(10, 216, 6, 0.4);
  }
  70% {
    box-shadow: 0 2px 0 8px rgba(245, 34, 45, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(245, 34, 45, 0);
  }
}

.badge-collapse-container {
  position: relative;
  display: inline-block;
  border-radius: 150px;
  animation: pulsCollapse 3s infinite;
}

.badge-more-container {
  position: relative;
  display: inline-block;
  border-radius: 150px;
  animation: pulseMore 3s infinite;
}

.form-layout {
  width: 100%;
}

.info-wrapper {
  width: 100%;
  padding: 20px 20px;
  background-color: #fff;
  border-radius: 2px;
  //box-shadow: $boxShadow;
}

@media only screen and (max-width: 600px) {
  .profile-info {
    width: 100%;
    margin-bottom: 20px;
  }

  .password-form {
    width: 100%;
  }

  .edit-profile {
    flex-wrap: wrap;
  }

  .input-col {
    width: 100% !important;
  }
}

.events {
  list-style: none;
  margin: 0;
  padding: 0;
}
.events .ant-badge-status {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 12px;
}
.notes-month {
  text-align: center;
  font-size: 28px;
}
.notes-month section {
  font-size: 28px;
}

////////////// Log History

.split-screen-history {
  display: grid;
  grid-template-columns: 50% 48%; /* Adjust the column widths as needed */
}

.left-side-history {
  padding-inline: 20px;
  background-color: #ffffff;
}

.right-side-history {
  border-radius: 10px;
  border: 1px solid #96ccff;
  background-color: #f8fcff;
  padding: 20px;
}

////////////// Calendars

.split-screen {
  display: flex;
  padding: 10px;
}

.left-side {
  flex: 1;
  padding: 20px;
  background-color: #ffffff;
}

.right-side {
  flex: 0.3;
  padding: 20px;
  background-color: #ecf4fb;
}

@media (max-width: 1500px) {
  // .split-screen {
  //   flex-direction: column; /* Switch to a vertical layout on smaller screens */
  // }
  .right-side {
    flex: 0.4;
    padding: 20px;
  }
}

.pill-container {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #dadada;
}

.approve-status {
  display: flex;
}

.approve-status-home {
  width: 100%;
  display: flex;
  margin-top: 20px;
  margin-bottom: -20px;
}

.button-delete-log-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.button-delete {
  margin-bottom: 0px;
}

.left-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.right-content {
  margin-right: -10px;
}

.calendar-hoilday-select {
  width: 100%;
  display: flex;
  gap: 5px;
  margin-bottom: -2.7rem;
}

.calendar-container .ant-fullcalendar-header .ant-radio-group {
  display: none !important;
}
.calendar-legend-header {
  margin-bottom: 20px;
  .ant-collapse-header {
    padding-bottom: 3px !important;
    padding-top: 5px !important;
    background-color: "#ffff" !important;
  }
}

.ant-fullcalendar-fullscreen .ant-fullcalendar-column-header {
  color: #1690ff;
  font-size: 18px;
  font-weight: bold;
}

.calendar-legend-wrapper {
  width: 20%;
  z-index: 9;
  position: absolute;
  .calendar-legend {
    width: 200px;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }
}

.ant-fullcalendar-fullscreen .ant-fullcalendar-today .ant-fullcalendar-date {
  background: #cde6ff !important;
}

.ant-fullcalendar-calendar-body {
  padding: 10px 0 !important;
}

.ant-calendar-disabled-cell-last-of-row .ant-calendar-date {
  border-top-right-radius: 20px !important;
  border-top-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
}

.ant-calendar-disabled-cell .ant-calendar-date {
  background: #96ccff !important;
  color: #fff !important;
}

.scrollable-div {
  width: 100%;
  white-space: nowrap;
  overflow-x: auto;
}
