.pre-wrap {
  white-space: pre-wrap;
  margin: 0;
}

.contracts-content {
  //background-color: white;
  overflow-x: auto;
  .add-contract {
    margin-left: auto;
  }
  .position-tag {
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.contracts-form {
  .ant-form-item {
    margin-bottom: unset;
  }
  .submit-button {
    margin-top: 20px;
  }
  .ant-form-item-children > div,
  .ant-form-item-children > span:not(.ant-select-arrow),
  .ant-form-item-children > input {
    width: 100%;
  }
}
