.selectOptions-container {
  padding: 10px;
  display: flex;
  align-items: center;

  .selectYear-container {
    margin: 0 10px;
  }
}
.monthlyUtilization-container {
  border-top: 0.2px solid #999;
}

.monthlyUtilizationTable {
  padding: 20px;
}

.selectOptions {
  width: 180px;
}

.yearlyUtilizationRate {
  padding: 10px;
  border: 1px solid black;
  display: inline;
}

.yearlyUtilizationRate-container {
  padding: 20px;

  h4 {
    margin-bottom: 10px;
  }
}

.yearlyUtilizationRate-heading,
.monthlyUtilizationRate-heading {
  margin: 0.4rem 0 1rem 0;
  display: flex;
  justify-content: center;
}

.paragraph {
  font-size: 16px;
  margin: 1rem;
}

.box {
  width: 400px;
  height: 120px;
  margin: 1rem 1rem;
  padding: 1.4rem;
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.boxes-container {
  display: flex;
  align-items: center;
}

.box-value {
  color: black;
  font-weight: bold;
}

.generatePDF-container button {
  justify-self: flex-end;
  margin: 0 20px;
}
