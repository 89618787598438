.m0 {
  margin: 0 !important;
}

.pl0 {
  padding-left: 0 !important;
}

.pr0 {
  padding-right: 0 !important;
}

// .fo-chart {
//   margin-top: 20px;
//   padding: 20px;
//   background-color: white;
//   border-radius: 2px;
// }

// .fo-chart-header {
//   display: inline-block;
//   color: rgba(0, 0, 0, 0.85);
//   margin-bottom: 15px;
// }
