.edit-button {
  width: 90px;
  margin-right: 10px;
  text-align: center;
}
.action-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.confirm-button {
  width: 100px;
}
.delete-button {
  width: 90px;
  margin-right: 10px;
  text-align: center;
}

.report-button {
  width: 90px;
  margin-right: 10px;
  text-align: center;
}

.ant-pagination-simple-pager input {
  pointer-events: none;
}
