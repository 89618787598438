.home-content {
  padding: 0;
}

//home

.grid-container {
  display: grid;
  grid-template-columns: 0.4fr 0.4fr 0.4fr;
  grid-gap: 10px;
  height: 100%;
  padding: 20px;
  // overflow-y: auto;
}

.reminders-container {
  background-color: #ffffff;
  margin-inline: 20px;
  margin-top: 20px;
  margin-bottom: -10px;
  padding-inline: 10px;
  padding-top: 10px;
  padding-bottom: 0px;
  border-radius: 5px;
  border: 1px solid #acdbfe;
}

.reminder-wrapper {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  max-width: 20%;
  border-radius: 100px;
  border: 1px solid #acdbfe;
}

.left-div {
  display: grid;
  // grid-template-rows: repeat(4, 1fr);
  grid-gap: 10px;
  height: 72vh;
  // overflow-y: auto;
  border-radius: 5px;
  // background-color: #ebf4fb;
}

.middle-div {
  display: grid;
  // grid-template-rows: repeat(4, 1fr);
  grid-gap: 10px;
  height: 72vh;
  // overflow-y: auto;
}

.right-div {
  display: grid;
  // grid-template-rows: repeat(4, 1fr);
  grid-gap: 10px;
  height: 72vh;
  // overflow-y: auto;
  border-radius: 5px;
}

.section {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #acdbfe;
}

.sectionNotify {
  background-color: #f8fcff;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #acdbfe;
}

.section1 {
  display: flex;
  background-color: #f8fcff;
  height: 7vh;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #acdbfe;
  overflow-y: auto;
}

.section2 {
  background-color: #ffffff;
  height: 60vh;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #acdbfe;
  overflow-y: auto;
}

.daily-quote {
  background-color: #def0fe;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #acdbfe;
}

.absence-card {
  .ant-card-body {
    padding-left: 10px !important;
    padding-right: 0px !important;
    padding-top: 10px !important;
  }
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-2px) rotate(-3deg);
  }
  50% {
    transform: translateX(2px) rotate(3deg);
  }
  75% {
    transform: translateX(-2px) rotate(-3deg);
  }
  100% {
    transform: translateX(0);
  }
}

.shake-hand {
  display: inline-block;
  animation: shake 1s infinite;
  padding-right: 10px;
}
