.wrapper-review {
  width: 100%;
  margin: 20px 0;
  padding: 10px;
  background-color: #fff;
  border-radius: 2px;
  // box-shadow: $boxShadow;
}
.review-buttons-wrapper {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: -3rem;
}

.split-screen-review {
  display: flex;
  gap: 5px;
}
.left-side-review {
  flex: 0.4;
  border-radius: 10px;
  border: 1px solid #96ccff;
}

.middle-side-review {
  flex: 0.5;
  border-radius: 10px;
  border: 1px solid #96ccff;
}

.right-side-review {
  flex: 0.5;
  background-color: #f8fcff;
  border-radius: 10px;
  border: 1px solid #96ccff;
}

.review-title {
  font-weight: bold;
  margin-bottom: 10px;
}
.manager-title {
  font-weight: bold;
}

.stats {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin-left: -3rem;
  margin-bottom: -20px;
}

.stats-description {
  margin-left: 10px;
}

.manager-comment-title-button {
  display: flex;
  justify-content: space-between;
}
.content-review-right {
  padding: 20px;
  padding-top: 0px;
  margin-top: -10px;
  gap: 10px;
  display: flex;
  flex-direction: column;
}
.content-review-middle {
  padding: 20px;
  width: 100%;
}

.content-review {
  padding: 20px;
  flex-direction: column;
}
.countdown {
  display: flex;
  justify-content: center;
}
.disabledStyles {
  pointer-events: none;
}
.manager-title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.manager-comment {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #acdbfe;
  height: 150px;
  overflow-y: auto;
}

.sticky-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
}

.private-comment {
  filter: blur(3px);
}

.title-reviewrs {
  margin-bottom: -8px;
}

.pill-container-review {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 5px;
  padding-inline: 10px;
  border: 1px solid #dadada;
}

.left-content-review {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.right-content-review {
  margin-right: -10px;
}
