.timelog-content {
  padding: 0;
}

.is-fullwidth {
  width: 100%!important;
}

.editorClassName {
  border: 1px solid rgb(217, 217, 217);
  border-radius: 4px;
  padding: 0 10px;
}

.editor-class {
  border: 1px solid black;
}

.spin {
  margin-left: 80px;
}

.show-tag-form {
  height: 93px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.timelog-actions {
  display: flex;
  flex-direction: row;
}

.timelog-actions .action-button {
  width: 95px;
  text-align: left;
  margin-right: 10px;
}

.date-picker-filter {
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 100px;
  align-items: center;
  justify-content: space-around;
}

.timelogs-reminder-container {
  display: flex;
}

.timelogs-reminder-container .timelogs-reminder-title {
  font-size: 11pt;
  padding-top: 10px;
}

.timelogs-reminder-container .timelogs-reminder-switch {
  padding: 10px 0 5px 10px;
}

.mr-8 {
  margin-right: 8px;
}

.mr-10 {
  margin-right: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mbr-2 {
  margin-bottom: 2rem;
}

.mt-28 {
  margin-top: 28px;
}

.cancel-icon {
  font-size: 20px;
  margin-top: 33px;
}

.float-r {
  float: right !important;
}

.float-l {
  float: left !important;
}

.newRowDiv {
  line-height: 0;
}

.addRowBtn {
  margin-top: 1rem;
}

.customDivider {
  margin: 17px 0 !important;
  margin-bottom: 10px !important;
}

.ant-row.ant-form-item {
  margin-bottom: 0 !important;
}

.timelogModal > .ant-modal-content {
  max-height: 85vh;
  overflow-y: auto;
}

::-webkit-scrollbar {
  height: 12px;
  width: 6px;
  background: #d4d4d4;
}

::-webkit-scrollbar-thumb {
  background: #001529;
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}

::-webkit-scrollbar-corner {
  background: #001529;
}
