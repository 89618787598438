.login {
  background-color: #ffffff;
  // box-shadow: $boxShadow;
  border-radius: 10px;
  width: 400px;
  text-align: center;
  padding: 15px 0;
  border: 1.5px solid #acdbfe;
  .ant-row {
    padding: 0 10px;
    text-align: left;
  }
  button {
    width: 90%;
    margin-top: 10px;
    height: 40px;
  }
  .login-form-forgot {
    display: flex;
    justify-content: flex-end;
    width: 95%;
    padding-top: 10px;
    .checkbox {
      display: flex;
      justify-content: flex-start;
    }
    .forgot-password-text {
      cursor: pointer;
      color: #42a8ff;
    }
  }

  .loginbutton-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.login-old {
  background-color: #ffffff;
  // box-shadow: $boxShadow;
  border-radius: 10px;
  width: 400px;
  text-align: center;
  padding: 15px 0;
  .ant-row {
    padding: 0 10px;
    text-align: left;
  }
  button {
    width: 90%;
    margin-top: 10px;
    height: 40px;
  }
  .login-form-forgot {
    display: flex;
    justify-content: flex-end;
    width: 95%;
    padding-top: 10px;
    .checkbox {
      display: flex;
      justify-content: flex-start;
    }
    .forgot-password-text {
      cursor: pointer;
      color: #42a8ff;
    }
  }

  .loginbutton-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }
}

.loginWrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.login-avatar {
  margin: 10px 0px 5px 0px !important;
  background-color: #ffffff !important;
  color: #001529 !important;
  border: solid 2px #001529 !important;
  font-size: 28pt !important;
}

.app-name {
  font-size: 25pt;
  color: #001529;
  letter-spacing: 1px;
  margin-bottom: -10px;
}

.other-title {
  font-size: 20pt;
  color: #001529;
  letter-spacing: 1px;
  margin: 10px 0px;
}

@media only screen and (max-width: 600px) {
  .login {
    width: 95%;
  }
}
