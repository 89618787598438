.page-not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 200%;
  background-image: url("../../assets/images/404.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  h1 {
    background-color: $white;
    text-align: center;
    border: 3px solid $grey;
    padding: 10px;
  }
}

.not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
