.actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

//.ant-calendar {
//  width: 230px !important;
//}

@media only screen and (max-width: 1200px) {
  .tableWrapper {
    width: 100vw !important;
    overflow: auto;
    padding-right: 20px;
  }
}

.leave-request-actions .action-button {
  width: 95px;
  text-align: left;
  margin: 2px 0;
}

.leave-request-actions .action-button-cancel {
  width: 95px;
  text-align: left;
  margin: 2px 0;
  background-color: #f89d43;
  border-color: #f89d43;
  color: white;
}

.leave-request-actions .action-button-cancel:hover,
.action-button-cancel:focus {
  background-color: #fbc086 !important;
  border-color: #fbc086 !important;
  color: white !important;
}

.deputy-buttons .deputy-button1 {
  margin-right: 5px;
  width: 100px;
}

.deputy-buttons .deputy-button2 {
  width: 100px;
}

.leave-request-details .wrapper .header-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.leave-request-details .wrapper .header-wrapper .deputy-actions {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.leave-request-details .wrapper .header-wrapper .deputy-request-breadcrumb {
  width: 60%;
}

.daysleft-statistic {
  padding: 10px 20px 15px 10px;
  display: flex;
}

.ant-statistic-content-suffix {
  color: black !important;
}

.under-management-leave-requests .holidays-statistic {
  display: flex;
  justify-content: space-around;
}

.skeleton .ant-skeleton-title {
  height: 40px !important;
}

.skeleton .ant-skeleton-paragraph li {
  height: 40px !important;
}

.year-filter {
  padding: 10px 10px 15px 20px;
}

.year-filter-deputy {
  padding: 10px 10px 15px 0;
}

.calendar-summary-layout {
  height: 90%;
}

.rbc-btn-group button {
  outline: none !important;
}

.calendar-skeleton .ant-skeleton-title {
  height: 30px !important;
}

.calendar-skeleton .ant-skeleton-paragraph li {
  height: 90px !important;
}

.emoji-leave-type span {
  font-size: 20pt !important;
}

.customer-header-style {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
}

.calendar-summery-export {
  margin-left: auto;
  display: flex;
  .select-employees {
    margin-right: 10px;
    width: 300px;
  }
}
