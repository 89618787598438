.roles-section {
  .save-permissions {
    display: flex;
    justify-content: center;
  }
}

.w100 {
  width: 100%!important;
}

@media only screen and (max-width: 1200px) {
  .roles-section {
    .permissions-table {
      overflow: auto;
      width: 100vh !important;
      padding-right: 20px;
    }
  }
}
