.document-content {
  padding: 0;
  .display-type {
    width: 100%;
    display: flex;
    justify-content: end;
    padding-right: 2rem;
    margin-bottom: -2rem;
    padding-bottom: 1rem;
  }
  .content-wrapper {
    padding-inline: 2rem;
    padding-top: 1rem;
    .divider-wrapper {
      display: flex;
      width: 100%;
      margin-top: -30px;
      .divider-text {
        font-weight: 300;
      }
    }
    .card-style {
      width: 200px;
      border-radius: 20px;
      border: 1px solid #acdbfe;
      .ant-card-actions {
        border-radius: 20px;
        border: 1px solid #ffffff;
      }
      .image-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        .image-card {
          width: 100px;
          padding-top: 1rem;
          margin-bottom: -0.5rem;
        }
      }
    }
  }
  .image-table {
    width: 30px;
  }
  .badge-style {
    margin-left: 10px;
  }
}
