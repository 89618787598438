@import "SideMenu/styles.scss";

#components-layout-demo-top-side .logo {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 28px 16px 0;
  float: left;
}

.logout-button {
  margin-top: auto;
}
.logout-button:hover {
  color: $red;
}

.trigger {
  font-size: 22px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
  color: #001529 !important;
}

.trigger:hover {
  color: #1890ff !important;
}

.logo {
  height: 32px;
  margin: 15px 1px;
  text-align: center;
  font-size: 18pt;
  color: white;
  letter-spacing: 3px;
  font-weight: 500;
}

.dashboard-container {
  position: relative;
  display: flex;
  height: 100%;
  overflow-y: auto;
  min-height: 100vh;
}

.content {
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
}

.content-container {
  width: 100%;
  overflow: auto;
}

.header {
  width: 100% !important;
  display: flex;
  padding: 0px 30px !important;
  background-color: #fff !important;
  justify-content: space-between;
}

.left-menu {
  height: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  overflow-x: hidden;
  box-shadow: $shadowRight;
}

.profile-dropwdown {
  color: black;
}

.wrapper {
  position: relative;
  padding: 20px;
  background-color: #fff;
  border-radius: 2px;
  overflow: auto;
}

.content-layout {
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
}

.user-name-dropdown {
  height: 100% !important;
  padding: 0 10px;
  cursor: pointer;
  color: #001529 !important;
}
.dropdown-div:hover {
  background-color: #fafafa !important;
  color: #1890ff !important;

  .avatar-11 {
    color: #1890ff !important;
  }
}

.overlay {
  border-radius: 0px !important;
}

.dropdown-menu {
  border-radius: 2px !important;
}

.logout-handler:hover {
  background-color: #fff !important;
  color: $red;
}

.menu-item :hover {
  background-color: #1890ff;
  color: white !important;
}

.section-title {
  width: 100%;
  background-color: white;
  padding: 20px;
  font-size: 20pt;
  font-weight: 400;
  color: #00152a !important;
  display: flex;
  align-items: center;
  &.bordered {
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
  }
}

@media only screen and (max-width: 600px) {
  .content {
    padding: 0px;
    display: flex;
    justify-content: flex-start;
    width: 365px;
    margin-top: 10px;
  }
  .timelog-content {
    padding: 0px !important;
  }

  .left-menu {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 5;
  }
  .content-container {
    margin: 0px !important;
    z-index: 4;
  }
}
