.mt-32 {
  margin-top: 32px;
}

.mb-30 {
  margin-bottom: 30px;
}

.pr {
  float: right;
}

.no-benchmark {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
