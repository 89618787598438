.user-avatar {
  text-align: center;

  img {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    border: none;
    outline: none;
  }

  p {
    margin: 0;
    padding: 0;
  }
}
.utilization-filters {
  .no-data-chart {
    display: flex;
    justify-content: center;
  }
}

.assign-deassign-modal {
  width: 100%;
}

.user-form-schroll {
  height: "70vh";
  overflow-y: "scroll";
  overflow-x: "hidden";
}
::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.stats {
  margin-top: 10px;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.activate-deactivate {
  width: 90px;
}

.piechart {
  .recharts-wrapper .recharts-surface {
    margin-left: 0 !important;
  }
}

.fr {
  float: right;
}
