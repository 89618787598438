.opposite-sides-menus {
  height: calc(100% - 95px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .menu {
    .text-size-menu {
      font-size: 15px;
    }

    .icon-size-menu {
      font-size: 18px;
    }
    .icon-size-menu-logout {
      font-size: 18px;
      color: red;
    }
  }
}
