@import '~antd/dist/antd.css';
@import './constants/index.scss';
@import './components/screens.scss';
@import './components/common.scss';
@import '../App.scss';
@import './vendors/fonts.scss';

.ant-table {
  font-family: 'Lato-Regular', sans-serif;
}

.ant-table-body {
  overflow-x: auto!important;
}

.links {
  display: inline-block;
}

.extra-top-margin {
  margin-top: 20px;
}
